import {Elements, CardElement, useStripe, useElements, LinkAuthenticationElement, PaymentElement} from "@stripe/react-stripe-js";
import PaymentStripeForm from "./PaymentStripeForm";
import {loadStripe} from "@stripe/stripe-js";
import {post} from "aws-amplify/api";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";

// publishableKey is ok for client browser. No need to secure.
const stripePromise = loadStripe("pk_test_51POKmN01RlNkki95Wk4jFJtGhe25kDiacFbLPKrhZd7sOqOW4rSYeAH2uzl4x2WEk3UBhd4s7Zl67LqjNfX6vf4B00YscNYnvD");
const loader = 'auto';

const PaymentForm = ({fetchClientSecret, total, formattedTotal}) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const email = useSelector(state => state.auth?.loginEmail);
    const phone = useSelector(state => state.userDetails?.phone);
    const full_name = useSelector(state => state.userDetails?.first_name + ' ' + state.userDetails?.last_name);

    useEffect(() => {
        const initializePayment = async () => {
            const clientSecret = await fetchClientSecret();
            setClientSecret(clientSecret);
        };
        initializePayment();
    }, [fetchClientSecret]);

    const onSubmit = async (data) => {
        setProcessing(true);
        const cardElement = elements.getElement(CardElement);

        const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: data.name,
                    email: data.email,
                },
            },
        });

        if (error) {
            setError(`Payment failed ${error.message}`);
            setProcessing(false);
        } else if (paymentIntent.status === "succeeded") {
            setError(null);
            setSucceeded(true);
            setProcessing(false);
        }
    };

    return (
        <form>
            <h3>Payment</h3>
            <PaymentElement
                options={{
                    defaultValues: {
                        billingDetails: {
                            email: email,
                            name: full_name,
                            phone: phone,
                        }
                    }
                }}
            />
            <button type="submit">Submit</button>
        </form>
    // <section
    //     aria-labelledby="payment-heading"
    //     className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
    // >
    //     <h2 id="payment-heading" className="sr-only">
    //         Payment details
    //     </h2>
    //     <div className="mx-auto max-w-lg lg:pt-16">
    //         <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
    //             <div>
    //                 <label htmlFor="name" className="block text-sm font-medium text-gray-700">
    //                         Name
    //                     </label>
    //                     <input
    //                         id="name"
    //                         name="name"
    //                         type="text"
    //                         {...register("name", {required: true})}
    //                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //                     />
    //                     {errors.name && <span className="text-red-500 text-sm">Name is required</span>}
    //                 </div>
    //                 <div>
    //                     <label htmlFor="email" className="block text-sm font-medium text-gray-700">
    //                         Email
    //                     </label>
    //                     <input
    //                         id="email"
    //                         name="email"
    //                         type="email"
    //                         {...register("email", {required: true})}
    //                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //                     />
    //                     {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
    //                 </div>
    //                 <div>
    //                     <label htmlFor="card" className="block text-sm font-medium text-gray-700">
    //                         Card Details
    //                     </label>
    //                     <CardElement
    //                         id="card"
    //                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //                     />
    //                 </div>
    //                 <button
    //                     type="submit"
    //                     disabled={processing || succeeded}
    //                     className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    //                 >
    //                     {processing ? "Processing..." : `Pay ${formattedTotal}`}
    //                 </button>
    //                 {error && (
    //                     <div className="text-red-500 text-sm mt-2">
    //                         {error}
    //                     </div>
    //                 )}
    //             </form>
    //         </div>
    //     </section>
    );
};

const PaymentGatewayCheckoutFormStripe = ({instr_id, products, formattedTotal, total, clientSecret}) => {
    const [errorInfo, setErrorInfo] = useState(null);

    const fetchClientSecret = async () => {
        try {
            const apiName = 'InstrOfScubaAPI';
            const path = '/stripe-payment';
            const options = {
                method: 'POST',
                body: {instr_id, items: products, hostname: window.location.hostname},
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            return data.clientSecret;
        } catch (error) {
            setErrorInfo('/stripe-payment\n' + error);
        }
    };

    const appearance = {
        theme: 'stripe',
    };

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div className="PaymentGateway">
            {/*<Elements options={{appearance}} stripe={stripePromise}>*/}
            <Elements options={{appearance, clientSecret}} stripe={stripePromise}>
                <PaymentForm total={total} formattedTotal={formattedTotal}/>
                {/*<PaymentStripeForm*/}
                {/*    fetchClientSecret={fetchClientSecret}*/}
                {/*    total={total}*/}
                {/*    formattedTotal={formattedTotal}*/}
                {/*/>*/}
            </Elements>
        </div>
    );
}

export default PaymentGatewayCheckoutFormStripe;
